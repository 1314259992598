import {
  ref, computed, watch, getCurrentInstance,
} from '@vue/composition-api'

import { INVOICE_APP_STORE_MODULE_NAME as INVOICE_STORE, INVOICE_LIST_COLUMNS, INVOICE_LIST_FIXED_COLUMNS } from '@/constants/invoice'
import store from '@/store'

import useToast from '@useToast'

export default function useInvoiceListHandle() {
  const { toastError, toastSuccess } = useToast()
  const vm = getCurrentInstance().proxy

  const refInvoiceListTable = ref(null)
  const loading = ref(false)

  const tableColumnInvoiceList = computed(() => {
    const columnsInStore = store.getters[`${INVOICE_STORE}/getColumnsList`]
    return INVOICE_LIST_COLUMNS
      .filter(col => {
        if (INVOICE_LIST_FIXED_COLUMNS.includes(col.key)) return true
        return columnsInStore.includes(col.key)
      })
  })
  async function onApplySelectColumnsHandle(columns) {
    await store.dispatch('app-invoice/setColumnsList', columns)
  }

  const invoicesList = ref([])
  // filter
  const startDate = ref('') // từ ngày
  const endDate = ref('') // đến ngày
  const invoiceStatus = ref('') // trạng thái hoá đơn
  const releaseStatus = ref('') // trạng thái phát hành
  const sendMailStatus = ref('') // trạng thái gửi mail
  const signStatus = ref('') // trạng thái gửi mail
  const agencyCode = ref('') // mã đại lý (theo đại lý nợ)
  const invNumber = ref('') // số hoá đơn
  const tagsFilter = ref([]) // nhãn
  const referenceCode = ref('') // mã tra cứu
  const buyerName = ref('') // tên khách hàng

  const sizePerPage = ref(10)
  const currentPage = ref(1)
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC

  const totalInvoices = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const clearFilter = () => {
    startDate.value = ''
    endDate.value = ''
    invoiceStatus.value = ''
    releaseStatus.value = ''
    sendMailStatus.value = ''
    tagsFilter.value = []
    signStatus.value = ''
    agencyCode.value = ''
    invNumber.value = ''
    referenceCode.value = ''
    buyerName.value = ''
  }

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  const fetchInvoices = (_ctx, callback) => {
    loading.value = true
    store
      .dispatch('app-invoice/fetchInvoices', {
        startDate: startDate.value || undefined,
        endDate: endDate.value || undefined,
        invoiceStatus: invoiceStatus.value || undefined,
        releaseStatus: releaseStatus.value || undefined,
        sendMailStatus: sendMailStatus.value || undefined,
        tags: tagsFilter.value.length ? tagsFilter.value : undefined,
        signStatus: signStatus.value || undefined,
        invNumber: invNumber.value || undefined,
        referenceCode: referenceCode.value || undefined,
        buyerName: buyerName.value || undefined,
        agencyReceiveCode: agencyCode.value || undefined,
        sortBy: sortBy.value ? sortBy.value : 'createdAt',
        sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
        size: sizePerPage.value,
        page: currentPage.value,
      })
      .then(res => {
        totalInvoices.value = res.count
        invoicesList.value = res.items
        callback(invoicesList.value)
      })
      .catch(error => {
        console.error('error', error)
        toastError('Lỗi tải danh sách hoá đơn')
      })
      .finally(() => {
        loading.value = false
      })
  }

  watch(
    [currentPage, sizePerPage, sortBy, isSortDirDesc],
    () => {
      refetchData()
    },
  )

  const exportInvoices = (invoiceIds = []) => new Promise((resolve, reject) => {
    vm.$bvModal.show('modal-api-loading')
    const payload = invoiceIds.length
      ? { invoiceIds }
      : {
        startDate: startDate.value || undefined,
        endDate: endDate.value || undefined,
        invoiceStatus: invoiceStatus.value || undefined,
        releaseStatus: releaseStatus.value || undefined,
        sendMailStatus: sendMailStatus.value || undefined,
        tags: tagsFilter.value.length ? tagsFilter.value : undefined,
        signStatus: signStatus.value || undefined,
        invNumber: invNumber.value || undefined,
        referenceCode: referenceCode.value || undefined,
        buyerName: buyerName.value || undefined,
        agencyReceiveCode: agencyCode.value || undefined,
        sortBy: sortBy.value ? sortBy.value : 'createdAt',
        sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
        size: sizePerPage.value,
        page: currentPage.value,
      }
    store
      .dispatch('app-invoice/exportInvoices', payload)
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'invoice.msg.exportSuccess',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.msg.exportError',
        })
        reject(e)
      })
      .finally(() => {
        vm.$bvModal.hide('modal-api-loading')
      })
  })

  return {
    // searchText,
    refInvoiceListTable,
    fetchInvoices,
    loading,
    refetchData,
    clearFilter,
    dataMeta,
    sizePerPage,
    currentPage,
    totalInvoices,
    onApplySelectColumnsHandle,

    // Filter
    isSortDirDesc,
    sortBy,
    startDate,
    endDate,
    invoiceStatus,
    releaseStatus,
    sendMailStatus,
    tagsFilter,
    signStatus,
    agencyCode,
    buyerName,
    referenceCode,
    invNumber,

    // constants
    INVOICE_STORE,
    tableColumnInvoiceList,

    exportInvoices,
  }
}
